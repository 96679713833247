export enum LFSEOfferType {
  Hotel = 'hotels',
  Tours = 'tours',
  UltraLux = 'ultralux',
  Experiences = 'experiences',
  Cruises = 'cruises'
}

type LookingForSomethingElseContent = {
  label: string;
  to: string;
  imageId?: string;
}

export const LOOKING_FOR_SOMETHING_ELSE_CONTENT = {
  hotels: { label: 'hotels', to: '/hotels', imageId: 'k58oh9ebyasvjo9ndff' } as LookingForSomethingElseContent,
  tours: { label: 'tours', to: '/tours', imageId: 'exu2capyq66fvv752yc4' } as LookingForSomethingElseContent,
  ultralux: { label: 'ultra lux', to: '/ultra-lux' } as LookingForSomethingElseContent,
  experiences: { label: 'experiences', to: '/experiences', imageId: 'ufils1y5jgf0yhvf5utk' } as LookingForSomethingElseContent,
  cruises: { label: 'cruises', to: '/cruises', imageId: 'wioy2m740nyf1gzxbr3v' } as LookingForSomethingElseContent,
  villas: { label: 'villas', to: '/homes-and-villas' } as LookingForSomethingElseContent,
}
